import DividendCalculator from './components/DividendCalculator';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <DividendCalculator />
    </div>
  );
}

export default App;