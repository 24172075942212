// DividendCalculator.js
import React, { useState } from 'react';
import './DividendCalculator.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive';

function DividendCalculator() {
  //인풋영역
  const [activeTab, setActiveTab] = useState('dividend');
  const [investment, setInvestment] = useState(0);                 // 투자금
  const [displayAmount, setDisplayAmount] = useState('0 원');     // 투자금액 한글 금액표시
  const [yieldRate, setYieldRate] = useState(0);                  // 배당수익률
  const [payoutFrequency, setPayoutFrequency] = useState('월배당');// 배당주기
  
  const [resultVisible, setResultVisible] = useState(false);      // 결과 표시 여부

  //결과영역
  const [interest, setInterest] = useState(0);                    // 이자금액
  const [resultPayoutFreq, setResultPayoutFreq] = useState('월'); // 배당주기
  const [bfDividend, setBfDividend] = useState(0);                // 세전배당금
  const [afDividend, setAfDividend] = useState(0);                // 세후배당금

  //초기화
  const handleReset = () => {
    setInvestment(0);
    setYieldRate(0);
    setPayoutFrequency('월');
    setResultVisible(false);
    setDisplayAmount('0 원');
  }

  //계산하기
  const handleCalculate = () => {
    const numYield = [12, 4, 2, 1];
    const frequency = ['월', '분기', '반기', '연'];
    //console.log ( "투자금 :"+ investment);
    //console.log ( "배당수익률 :"+ yieldRate);
    //console.log ( "배당주기 :"+ payoutFrequency);

    frequency.map((item, idx) => {
      if( frequency[idx] === payoutFrequency ) {
        let amt = investment * (yieldRate/100) * (1/numYield[idx]);
        //세전배당금
        setBfDividend(Math.round(amt));
        //세금
        setInterest(Math.round(amt * 0.154));
        //세후배당금
        setAfDividend(Math.round(amt- (amt * 0.154)));
        setResultPayoutFreq(item.replace('배당', '')+'마다');
      }
      return 0;
    });
    setResultVisible(true);
  };

  // 숫자를 한글 금액으로 변환하는 함수
  const convertToKorean = (num) => {
    const units = ['', '만', '억', '조'];
    let result = '';
    let unitIndex = 0;

    while (num > 0) {
      const chunk = num % 10000;
      if (chunk > 0) result = `${chunk}${units[unitIndex]} ` + result;
      num = Math.floor(num / 10000);
      unitIndex++;
    }
    return result.trim() + '원';
  };
/*
  // 숫자를 한글 금액으로 변환하는 함수
  const convertToKorean = (num) => {
    if (num === 0) return '0원';
  
    const units = ['', '만', '억', '조', '경'];
    const smallUnits = ['', '천', '백', '십'];
    let result = '';
    let unitIndex = 0;
  
    while (num > 0) {
      let chunk = num % 10000;  // 만 단위로 끊기
      let chunkStr = '';
  
      for (let i = 0; i < smallUnits.length; i++) {
        const digit = chunk % 10;
        if (digit > 0) {
          chunkStr = digit + smallUnits[i] + chunkStr;
        }
        chunk = Math.floor(chunk / 10);
      }
  
      if (chunkStr) {
        result = chunkStr + units[unitIndex] + ' ' + result;
      }
  
      num = Math.floor(num / 10000);
      unitIndex++;
    }
  
    return result.trim() + '원';
  };
  */
  
  
  

  // 숫자를 2자리마다 콤마로 구분된 문자열로 변환하는 함수
  const convertToComma = (num) => {
    return num.toLocaleString();
  }

  const handleInvestmentChange = (e) => {
    const value = parseInt(e.target.value || 0);  //value가 null, undefined, 또는 빈문자열일 때 0을 대신 사용
    setInvestment(value);
    setDisplayAmount(convertToKorean(value));
    reCalculate();
  };

  // 배당 주기 선택 시 결과에 보여줄 배당주기문구 생성
  const handlePayoutFrequency = (freq) => {
    setPayoutFrequency(freq);
    reCalculate();
  };
  
  const handleYieldRate = (num) => {
    setYieldRate(num.target.value || 0);
    reCalculate();
  };
  
  const handleYieldRateBtn = (num) => {
    setYieldRate(num|| 0);
    reCalculate();
  };
  
    

  const addAmount = (amount) => {
    const newAmount = investment + amount;
    setInvestment(newAmount);
    setDisplayAmount(convertToKorean(newAmount));
    reCalculate();
  };

  //탭이동 시 
  const setTab = (type) => {
    setResultVisible(( type === 'dividend' ) ? true : false);
    setActiveTab(type);
  };

  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 1199px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  const getWidth = () => {
    if (isMobile) { return '100%'; }
    else if (isTablet) { return '400px'; }
    else if (isDesktop) { return '400px'; }
  };  

  function reCalculate() {
    //계산하기가 이미 실행되어 result가 보이고 있을때 
    if(resultVisible) {
      //handleCalculate();
      setResultVisible(false);
    } else {

    }
  };

  return (
    <div className="all-container" style={{ width: getWidth() }}>
      <div className="tabs">
        <button className={activeTab === 'dividend' ? 'active' : ''} onClick={() => setTab('dividend')} > 배당 계산 </button>
        <button className={activeTab === 'reinvest' ? 'active' : ''} onClick={() => setTab('reinvest')} > 배당 재투자 </button>
      </div>

      {activeTab === 'dividend' && (
        <div className="tab-content">

          <div className="item-container">
            <div className="item-title ls5"><label>투자금</label></div>
            <div className="item-input">
              <div className="input_bx"> 
                <input type="number" value={investment} onChange={handleInvestmentChange}  placeholder="0" autoComplete="off" /><span className="unit">원</span> 
              </div>

              <div className="display-amount">{displayAmount}</div>

              <div className="amount-buttons">
                <button onClick={() => addAmount(100000)}>+십만</button>
                <button onClick={() => addAmount(1000000)}>+백만</button>
                <button onClick={() => addAmount(10000000)}>+천만</button>
                <button onClick={() => addAmount(100000000)}>+억</button>
              </div> 
            </div>
          </div>

          <div className="item-container">
            <div className="item-title"><label>배당수익률</label></div>
            <div className="item-input">
              <div className="input_bx"> 
                <input type="text" value={yieldRate} onChange={handleYieldRate} placeholder="0" autoComplete="off" /><span className="unit">%</span> 
              </div>
              <div className="yield-buttons">
                {[3, 5, 7, 10].map((rate) => (
                  <button
                    key={rate}
                    className={rate === yieldRate ? 'active' : ''}
                    /*onClick={() => setYieldRate(rate)}*/
                    onClick={() => handleYieldRateBtn(rate)}
                  >
                    {rate}%
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="item-container">
            <div className="item-title"><label>배당주기</label></div>
            <div className="item-input">
              <div className="payout-buttons">
                {['월', '분기', '반기', '연'].map((freq) => (
                  <button
                    key={freq}
                    className={freq === payoutFrequency ? 'active' : ''}
                    /*onClick={() => setPayoutFrequency(freq)}*/
                    onClick={() => {handlePayoutFrequency(freq)}}
                  >
                    {freq}
                  </button>
                ))}
              </div>
            </div>            
          </div>

          <div className="actions">
            <button className="reset" onClick={handleReset}><i className="fas fa-redo-alt"></i> 초기화</button>
            <button className="calculate" onClick={handleCalculate}><i className="fa-solid fa-calculator"></i> 계산하기</button>
          </div>   
          
                 
        </div>
      )}

      {resultVisible && (
        <div className="result">
          <Row className='result-summary'>
            <Col>투자금 <b>{displayAmount}</b>을<br/> 배당수익율 <b>{yieldRate}%</b>의 <b>{payoutFrequency}배당</b> 상품에 투자하면<br/> <b>{resultPayoutFreq} 
                  <span className="highlight">&nbsp;{convertToKorean(afDividend)}</span></b>의 배당금을 수령 할 수 있습니다.
            </Col>
          </Row>

          <div className="result-tax">
            <Row><Col>세전 배당금</Col><Col className='display-amount'> {convertToComma(bfDividend)} 원</Col></Row>
            <Row><Col>이자과세(15.4%)</Col><Col className='display-amount'> -{convertToComma(interest)} 원</Col></Row>
          </div>
          <div className="result-total">
            <Row><Col>세후 배당금</Col><Col className='display-amount'> {convertToComma(afDividend)} 원</Col></Row>
          </div>
        </div>
      )}

      {activeTab === 'reinvest' && <div className="tab-content">배당 재투자 기능 준비 중...</div>}
    </div>
  );

};
  
export default DividendCalculator;